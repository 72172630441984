import MDRouter from "@/service/router";
import { useRoute } from 'vue-router';
import { mapState } from "vuex";
// eslint-disable-next-line no-unused-vars
import { postOrder, get, post } from "@/utils/apiBase.js";
import lowerdev from "@/service/lowerdev";
import domain from "@/service/domain";
import { env, domClientWidth, domPixelRatio } from "md-base-tools/env";
// eslint-disable-next-line no-unused-vars
import { getOrderTitleList, getOrderList, getScheme, getPayMethodList } from "@api";
// eslint-disable-next-line no-unused-vars
import { showConfirmDialog, showDialog, showToast } from "vant";
import orderProjectRefund from "@/components/order_detail/orderProjectRefund";
import DialogBtn from "@/components/order_detail/dialogBtn";
import orderRemark from "@/components/order_detail/orderRemark";
import orderAppeal from "@/components/order_detail/orderAppeal";
import orderRefund from "@/components/order_detail/orderRefund";
import orderTel from "@/components/order_detail/orderTel";
import topay from "@/components/order_detail/topay";
import itemli from "@/components/order/itemli";
import afterSaleList from "./after_sale_list";
import paypay from '@/utils/paypay.js';
import { mdToast } from "../../utils/tools";
export default {
  name: "orderList",
  components: {
    itemli,
    DialogBtn,
    topay,
    orderAppeal,
    orderRemark,
    orderRefund,
    orderTel,
    orderProjectRefund,
    afterSaleList
  },
  async setup() {
    const route = useRoute();
    const after = route.query.after == 'true' ? true : false;
    let titleList;
    var topTip;
    if (!after) {
      const {
        data
      } = await getOrderTitleList({
        json_type: "json"
      });
      titleList = data;
      if (data && data[0].top_tip) {
        topTip = data[0].top_tip;
      }
    } else {
      titleList = [
      // {
      //   code: 105, title: "退款",
      // },
      {
        code: 200,
        title: "售后"
      }];
    }
    return {
      titleList,
      topTip,
      after
    };
  },
  data() {
    return {
      env: env,
      isMobile: env.isMobile(),
      list: [],
      placeholder: "~@assets/images/orderlist/default_1x1@2x.png",
      io: null,
      tablist: [],
      tab_status: [],
      nav: 0,
      order_id: "",
      miniProgram: "",
      appealShow: false,
      oneListCode: "",
      refundMsg: "",
      refundInfo: null,
      orderProjectRefundShow: false,
      dialog_btn: [],
      addressBtn: [{
        name: "去地址簿选地址",
        title: "去地址簿选地址",
        type: 1
      }, {
        name: "编辑当前订单地址",
        title: "编辑当前订单地址",
        type: 0
      }],
      oneListShow: false,
      dialog_address: false,
      dialog_show: false,
      remarkShow: false,
      remark: "",
      telShow: false,
      orderRefundShow: false,
      loading: true,
      payShow: false,
      giftWrapShow: false,
      openwxmina: false,
      page_index: 1,
      orderInfo: {},
      orderitem: {},
      html: "",
      orderType: "",
      order_type: "",
      orderId: "",
      payPay: null,
      pay_resource: '',
      // 支付方式
      methodsList: [] // 支付方式数组
    };
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    }),
    if_show() {
      return !(this.env.isWeibo() || this.env.isWeixin() || this.env.isModianIos() || this.env.isModianAndroid());
    },
    // 不显示引导下载APPheader
    if_no_header() {
      return env.isWeibo() || env.isModianIos() || env.isModianIosNew() || env.isModianAndroid() || !env.isMobile();
    },
    imgWidth() {
      return Math.round(domClientWidth() * domPixelRatio());
    }
  },
  beforeMount() {
    if (sessionStorage.getItem("miniProgram") === "weixin") {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.4.0.js");
    }
    // 初始化支付工具
    this.payPay = new paypay({
      success: this.PaySuccessBack,
      type: 'pro'
    });
  },
  mounted() {
    if (!this.if_no_header) {
      document.querySelectorAll(".order-list")[0].setAttribute("class", "order-list active viewBox");
    }
    lowerdev.addbind(document.getElementsByClassName("order-list")[0], "scroll", this.reloadScroll);
    // eslint-disable-next-line eqeqeq
    this.miniProgram = sessionStorage.getItem("miniProgram") == "weixin";
    if (this.$route.query.nav) {
      this.nav = this.$route.query.nav;
    } else {
      this.nav = this.titleList[0].code;
    }
    const that = this;
    document.addEventListener("visibilitychange", function () {
      // 用户息屏、或者切到后台运行 （离开页面）
      if (document.visibilityState === "hidden") {
        console.log("hidden");
      }
      // 用户打开或回到页面
      if (document.visibilityState === "visible") {
        console.log("页面可见");
        if (that.openwxmina) {
          location.reload();
        }
      }
    });
    // eslint-disable-next-line eqeqeq
    if (this.nav == 107) {
      this.jumpOneList(107);
    } else if (this.nav != 200) {
      this.getList();
    }
  },
  beforeUnmount() {
    lowerdev.removebind(document.getElementsByClassName("order-list")[0], "scroll", this.reloadScroll);
  },
  methods: {
    goNext() {
      var searchParams = {
        url: "/apis/mdorder/refund/create",
        method: "post",
        props: {
          order_id: this.orderId,
          refund_reason: this.refundMsg
        }
      };
      postOrder(searchParams.url, searchParams.props).then(resa => {
        if (resa) {
          window.location.reload();
          mdToast("操作成功");
        }
      });
    },
    ifAddress() {
      get("/apis/address/my_address_count", {
        user_id: this.userId
      }).then(({
        data
      }) => {
        if (data > 1) {
          this.dialog_address = true;
        } else {
          if (this.orderInfo.order_type !== 4) {
            this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=project`);
          } else {
            this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=shop`);
          }
        }
      });
    },
    toAddress(e) {
      if (e === 0) {
        if (this.orderInfo.order_type !== 4) {
          this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=project`);
        } else {
          this.$router.push(`/address/add_address?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=shop`);
        }
      } else {
        if (this.orderInfo.order_type !== 4) {
          this.$router.push(`/address/address_list?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=project`);
        } else {
          this.$router.push(`/address/address_list?redirectUrl=${encodeURIComponent(location.href)}&orderId=${this.orderId}&orderType=shop`);
        }
      }
    },
    close() {
      showToast({
        type: "text",
        message: "操作成功",
        onClose: () => {
          window.location.reload();
        }
      });
    },
    jumpShop(id) {
      location.href = domain.wap + "/product/pop/" + id + ".html";
    },
    jumpProduct() {},
    jumpOneList(code) {
      this.nav = code;
      this.$router.push({
        query: {
          nav: code
        }
      });
      this.oneListCode = code;
      this.oneListShow = true;
      // eslint-disable-next-line no-undef
      userHeader.backnavOnShow(false);
      this.page_index = 1;
      this.list = [];
      document.documentElement.scrollTop = 0;
      this.getList();
    },
    dropBack() {
      if (this.oneListShow) {
        // document.documentElement.scrollTop = 0;
        this.list = [];
        this.nav = 100;
        this.page_index = 1;
        this.oneListShow = false;
        this.$router.push({
          query: {
            nav: 100
          }
        });
        // eslint-disable-next-line no-undef
        userHeader.backnavOnShow(true);
        this.getList();
      } else {
        if (history.length > 1) {
          this.$router.back(-1);
        } else {
          lowerdev.openUrl();
        }
      }
    },
    show_dialog({
      arr,
      id,
      item,
      type
    }) {
      let arrStore = JSON.parse(JSON.stringify(arr));
      arrStore = arrStore.splice(0, arrStore.length - 2);
      this.dialog_btn = arrStore;
      this.orderitem = item;
      this.order_id = id;
      this.dialog_show = true;
      this.orderType = type;
    },
    close_dialog() {
      this.dialog_show = false;
    },
    op({
      type,
      item,
      orderType,
      listIndex
    }) {
      const that = this;
      if (item) {
        this.orderInfo = item;
        this.orderitem = item;
      }
      this.orderType = orderType;
      this.orderId = item.order_id;
      // 备注----
      if (this.orderInfo.order_type !== 4) {
        this.remark = item.order_list ? item.order_list[0].remark : "";
      } else {
        this.remark = item.remark;
      }
      // 备注----
      const orderId = this.orderId;
      // eslint-disable-next-line no-case-declarations
      switch (type) {
        case "subscribe_records":
          // 查看记录
          if (this.orderInfo.order_type !== 4) {
            // eslint-disable-next-line eqeqeq
            location.href = `${domain.wap}/user_order/subscribe_record/${this.orderInfo.pro.product_info.pro_id}`;
          }
          break;
        case "del_order":
          // 确认删除
          showConfirmDialog({
            message: "是否确认要删除？"
          }).then(() => {
            var searchParams = {
              url: "/mall/order/delete",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            if (this.orderInfo.order_type !== 4) {
              searchParams.url = "/apis/mdorder/order_del";
              searchParams.props.user_id = this.user_id;
              postOrder(searchParams.url, searchParams.props).then(res => {
                if (res) {
                  showToast({
                    type: "text",
                    message: "删除成功",
                    onClose: () => {
                      this.list.splice(listIndex, 1);
                    }
                  });
                }
              });
            } else {
              postOrder(searchParams.url, searchParams.props).then(res => {
                if (res) {
                  showToast({
                    type: "text",
                    message: "删除成功",
                    onClose: () => {
                      this.list.splice(listIndex, 1);
                    }
                  });
                }
              });
            }
          });
          break;
        case "cancel_order":
          // 确认取消订单
          showConfirmDialog({
            message: "是否确认要取消？"
          }).then(() => {
            var searchParams = {
              url: "/mall/order/cancel",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            if (this.orderInfo.order_type !== 4) {
              searchParams.url = "/apis/mdorder/order_cancel";
              searchParams.props.user_id = this.user_id;
              postOrder(searchParams.url, searchParams.props).then(res => {
                if (res) {
                  showToast({
                    type: "text",
                    message: "取消成功",
                    onClose: () => {
                      window.location.reload();
                    }
                  });
                }
              });
            } else {
              postOrder(searchParams.url, searchParams.props).then(res => {
                if (res) {
                  showToast({
                    type: "text",
                    message: "取消成功",
                    onClose: () => {
                      window.location.reload();
                    }
                  });
                }
              });
            }
          });
          break;
        case "update_address":
          // 确认修改地址
          this.ifAddress();
          break;
        case "apply_update_address":
          // 申请修改地址
          if (this.orderInfo.order_type !== 4) {
            get("/apis/mdorder/check_address_lock", {
              type: "order_id",
              id: this.orderId
            }).then(e => {
              if (e.data.address_status_lock) {
                showDialog({
                  message: "您的申请商家审核中"
                }).then(() => {
                  // on close
                });
              } else {
                showConfirmDialog({
                  title: "温馨提示",
                  message: "您购买的回报在不同地区收取的邮费不同,建议您提前与发起方沟通协商邮费相关事项,以免申请被发起人拒绝",
                  confirmButtonText: "申请改地址"
                }).then(() => {
                  this.ifAddress();
                  // on confirm
                }).catch(() => {
                  // on cancel
                });
              }
            });
          }
          break;
        case "update_remark":
          // 确认修改备注
          if (this.orderInfo.order_type !== 4) {
            this.remarkShow = true;
          } else {
            window.location.href = `${domain.wap}/user_order/update_order_des_shop/${orderId}`;
          }
          break;
        case "look_teamfund":
          location.href = domain.wap + "/teamfund/" + this.orderInfo.pro.teamfund_id + ".html";
          console.log(domain.wap + "/teamfund/" + this.orderInfo.pro.teamfund_id + ".html");
          break;
        case "buy_again":
          // webview  点击后跳转至商品详情页
          if (this.orderInfo.extract_card_type && this.orderInfo.extract_card_type > 0) {
            MDRouter.goLuckycardsInfo(this.orderInfo.extract_card_id);
            return false;
          }
          if (this.orderInfo.order_type !== 4) {
            if (this.miniProgram || this.env.isWxApp()) {
              MDRouter.MinaRouter('ProInfo', {
                pro_id: this.orderInfo.pro.product_info.pro_id
              });
            } else {
              MDRouter.goProInfo(this.orderInfo.pro.product_info.pro_id);
            }
          } else {
            lowerdev.openUrl("product", this.orderInfo.mall.shop_info.shop_id);
          }
          break;
        case "update_mobile":
          // 确认修改手机号  --> 需要先获取收货地址
          if (this.orderInfo.order_type !== 4) {
            this.telShow = true;
            // lowerdev.confirm({ msg: '是否确认要修改手机号？' }, () => {
            //   window.location.href = `${domain.wap}/user_order/update_order_mobile/${orderId}`
            // })
          } else {
            window.location.href = `${domain.wap}/user_order/update_order_mobile_shop/${orderId}`;
          }
          break;
        case "create_tail":
          if (this.orderInfo.mall.shop_info.skus && this.orderInfo.mall.shop_info.skus[0].sku_id) {
            const arr = this.orderInfo.mall.shop_info.skus.map(e => {
              return String(e.sku_id);
            });
            const obj = {
              presale_pay_type: 2,
              first_order_id: String(orderId),
              sku_id: arr
            };
            location.href = `${domain.wap}/product/payment?order_final_arg=${JSON.stringify(obj)}`;
          }
          break;
        case "pay":
          // 去支付
          if (this.orderInfo.extract_card_type && this.orderInfo.extract_card_type > 0) {
            const luckycardsData = {
              apply_pay_amount: this.orderInfo.apply_pay_amount,
              order_id: orderId
            };
            sessionStorage.setItem("luckycardsData", JSON.stringify(luckycardsData));
            MDRouter.goLuckycardsInfo(this.orderInfo.extract_card_id, {
              luckycardsData: JSON.stringify(luckycardsData)
            });
            return false;
          }
          this.toPay();
          break;
        case "delay":
          // 确认延长收货
          if (this.orderInfo.order_type !== 4) {
            const searchParams = {
              url: "/apis/mdorder/order_delay_recv",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast("操作成功");
                this.$router.go(0);
              }
            });
          } else {
            if (this.orderInfo.deliver_7 === 0) {
              showToast({
                msg: "系统自动签收前3天才可延长发货"
              });
            } else {
              const searchParams = {
                url: "/mall/order/goods/delay",
                method: "post",
                props: {
                  order_id: orderId
                }
              };
              postOrder(searchParams.url, searchParams.props).then(res => {
                if (res) {
                  showToast("操作成功");
                  this.$router.go(0);
                }
              });
            }
          }
          break;
        case "cancel_order_refund":
          // 撤销退款
          showConfirmDialog({
            message: "确定要撤销退款吗？"
          }).then(() => {
            var searchParams = {
              url: "/mall/order/goods/confirm",
              method: "post",
              props: {
                refund_id: that.orderInfo.refund_id
              }
            };
            if (that.orderInfo.order_type !== 4) {
              searchParams.url = "/apis/mdorder/refund/cancelled";
            }
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast({
                  type: "text",
                  message: that.orderInfo.order_type !== 4 ? res.message : res.sys_msg,
                  onClose: () => {}
                });
                this.$router.go(0);
              }
            });
            // on confirm
          }).catch(() => {
            // on cancel
          });
          break;
        case "refund_appeal":
          // 众筹申诉
          this.appealShow = true;
          break;
        case "appeal":
          // 电商申诉
          this.gotoDetails({
            item: that.orderInfo
          });
          break;
        case "confirm":
          // 确认-确认收货
          showConfirmDialog({
            message: "请您确认已收到全部商品后再点击“确认收货”，一旦确认将无法撤回。"
          }).then(() => {
            var searchParams = {
              url: "/mall/order/goods/confirm",
              method: "post",
              props: {
                order_id: orderId
              }
            };
            if (that.orderInfo.order_type !== 4) {
              searchParams.url = "/apis/mdorder/order_recv";
            }
            postOrder(searchParams.url, searchParams.props).then(res => {
              console.log(res);
              if (res) {
                showToast({
                  type: "text",
                  message: that.orderInfo.order_type !== 4 ? res.message : res.sys_msg,
                  onClose: () => {}
                });
                that.giftWrapShow = true;
              }
            });
          });
          break;
        case "order_comment_add":
          // 发表评价
          if (this.orderInfo.order_type !== 4) {
            location.href = `${domain.wap}/product/evaluate/initiate?state=1&order_id=${orderId}&product_id=${this.orderInfo.pro.product_info.pro_id}&rew_id=${this.orderInfo.pro.pay_items[0].item_id}`;
          } else {
            this.orderDetailCallback(() => {
              location.href = `${domain.wap}/product/evaluate/initiate?state=1&order_id=${orderId}&product_id=${this.orderInfo.mall.shop_info.skus[0].product_id}&sku_id=${this.orderInfo.mall.shop_info.skus[0].sku_id}`;
            });
          }
          break;
        case "order_comment_edit":
          // 商城订单专属  -->  修改评论
          // 编辑评价
          window.location.href = `${domain.wap}/order_rate/update_rate?back_id=${orderId}&type=shop`;
          break;
        case "order_comment_detail":
          // 查看评论
          if (this.orderInfo.order_type !== 4) {
            location.href = `${domain.wap}/product/evaluate?state=1&order_id=${orderId}&product_id=${this.orderInfo.pro.product_info.pro_id}&rew_id=${this.orderInfo.pro.pay_items[0].item_id}`;
          } else {
            this.orderDetailCallback(() => {
              window.location.href = `${domain.wap}/product/evaluate?order_id=${orderId}&product_id=${this.orderInfo.mall.shop_info.skus[0].product_id}&sku_id=${this.orderInfo.mall.shop_info.skus[0].sku_id}`;
            });
          }
          break;
        case "apply_after":
          // 商城订单专属 --> 申请售后
          lowerdev.openUrl("refund/apply", {
            soid: orderId
          });
          break;
        case "look_logistics":
          // webview 商城订单专属  --> 查看物流
          lowerdev.openUrl("logistics", {
            order_id: orderId
          });
          break;
        case "look_goods":
          // webview 商城订单专属  --> 查看发货
          lowerdev.openUrl("logistics", {
            order_id: orderId
          });
          break;
        case "reminder":
          // 商城订单专属  -->  催单
          if (this.orderInfo.pay_success_time) {
            // eslint-disable-next-line camelcase
            var reminder_flag = new Date().getTime() - new Date(this.orderInfo.pay_success_time.replace(/-/g, "/")).getTime();
            // eslint-disable-next-line camelcase
            if (reminder_flag < 48 * 60 * 60 * 1000) {
              showToast("支付48小时后才可催单");
            } else {
              const searchParams = {
                url: "/mall/order/reminder",
                method: "post",
                props: {
                  user_id: this.userId,
                  order_id: orderId
                }
              };
              postOrder(searchParams.url, searchParams.props).then(() => {
                showToast("已提醒商家优先为你发货");
              });
            }
          } else {
            showToast("已提醒商家优先为你发货");
          }
          break;
        case "refund_detail":
          window.location.href = `${domain.wap}/user_order/refund_detail/${this.orderInfo.order_id}`;
          break;
        case "apply_refund":
          // 申请退款 webview
          if (this.orderInfo.order_type !== 4) {
            this.orderRefundShow = true;
          } else {
            lowerdev.openUrl("refund", {
              soid: orderId
            });
          }
          break;
        case "cancel_refund":
          // 撤销退款
          showConfirmDialog({
            message: "确定要撤销本次退款申请吗？"
          }).then(() => {
            var searchParams = {
              url: "/mall/order/refund/cancel",
              method: "post",
              props: {
                refund_id: that.orderInfo.refund_id
              }
            };
            postOrder(searchParams.url, searchParams.props).then(res => {
              if (res) {
                showToast("操作成功");
                this.$router.go(0);
              }
            });
          });
          break;
        case "update_refund":
          // 更新退款 webview
          // 跳转退款页面
          lowerdev.openUrl("refund", {
            rid: this.orderInfo.refund_id
          });
          break;
        case "apply_deliver":
          //抽卡机发货按钮
          window.location.href = `${domain.wap}/luckycards/container`;
          break;
        case "look_card_goods":
          //抽卡机查看发货单
          window.location.href = `${domain.wap}/luckycards/container_order_list`;
          break;
        default:
          return false;
      }
    },
    async toPay() {
      let option = {
        type: 3,
        // 支付类型 1、众筹项目id 2、电商spuid 3、订单id
        id: this.orderId // 统一数据id，根据类型判断，有多个逗号分隔
      };
      const {
        status,
        data,
        message
      } = await getPayMethodList(option);
      if (status == 0) {
        if (data) {
          this.methodsList = data;
          const payMethod = data.find(obj => obj.is_default === 1) || data[0];
          this.pay_resource = payMethod.pay_type;
          // 仅有一种支付方式则直接调起支付
          if (data.length == 1) {
            this.payClick(payMethod.pay_type);
          } else {
            this.payShow = true;
          }
        } else {
          showToast('此订单不支持当前支付方式');
        }
      } else {
        showToast(message);
      }
    },
    payClick(pay_resource) {
      const orderId = this.orderId;
      let obj = {
        pay_id: this.orderInfo.pay_id || '',
        user_id: this.user_id,
        pay_resource: pay_resource
      };
      sessionStorage.setItem('FailNext', 'reload');
      // order_type 0 众筹 4 电商
      if (this.orderInfo.order_type !== 4) {
        this.payPay.toPay({
          params: obj,
          order_id: orderId
        });
      } else {
        this.payPay.toPay({
          params: obj,
          type: 'mall',
          order_id: orderId
        });
      }
    },
    back() {
      this.$router.back(-1);
    },
    navTab(idx) {
      document.documentElement.scrollTop = 0;
      this.nav = idx;
      this.page_index = 1;
      this.list = [];
      this.getList();
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          nav: idx
        })
      });
    },
    gotoDetails({
      item
    }) {
      // return (this.orderRefundShow = true)
      // eslint-disable-next-line no-unreachable
      let orderId = "";
      orderId = item.order_id;
      // eslint-disable-next-line eqeqeq
      if (this.nav == "105") {
        if (item.order_type !== 4) {
          location.href = `${domain.wap}/user_order/refund_detail/${orderId}`;
        } else {
          location.href = `${domain.wap}/product/refund/${item.refund_id}`;
        }
      } else {
        if (item.order_type === 0) {
          // console.log(item)
          if (item.style === "merger") {
            this.$router.push(`/order/project_order_detail?order_id=${item.pay_id}&style=merger`);
          } else {
            this.$router.push(`/order/project_order_detail?order_id=${orderId}`);
          }
        } else if (item.order_type === 2 || item.order_type === 1) {
          if (item.style === "merger") {
            this.$router.push(`/order/project_order_detail?order_id=${item.pay_id}&style=merger`);
          } else {
            this.$router.push(`/order/project_order_detail?order_id=${orderId}`);
          }
        } else if (item.order_type === 3) {
          location.href = `${domain.wap}/user_order/order_detail/${item.pay_id}/${orderId}`;
        } else if (item.order_type === 4) {
          if (this.miniProgram) {
            MDRouter.MinaRouter('MallOrder', {
              order_id: orderId
            });
          } else {
            this.$router.push(`/order/product_order_detail?order_id=${orderId}`);
          }
        } else {
          location.href = `${domain.wap}/product/order_detail?order_id=${orderId}`;
        }
      }
    },
    getList() {
      const status = this.oneListShow ? this.oneListCode : this.nav;
      getOrderList({
        status: String(status),
        page: this.page_index,
        page_size: 10
      }).then(({
        data
      }) => {
        const arr = data.list;
        if (arr && arr.length) {
          arr.map(e => {
            if (e.status_data && e.status_data.btn_list) {
              e.status_data.btn_list = e.status_data.btn_list.reverse();
            }
          });
          this.list.push(...arr);
          this.loading = false;
        }
      });
    },
    refundFn(e) {
      const orderId = this.orderId;
      showConfirmDialog({
        message: "确定要申请退款吗？"
      }).then(() => {
        get("/apis/mdorder/related_reward", {
          order_id: orderId
        }).then(res => {
          if (res.data && res.data.list && res.data.list.length) {
            this.refundMsg = e;
            this.refundInfo = res.data;
            this.orderProjectRefundShow = true;
          } else {
            var searchParams = {
              url: "/apis/mdorder/refund/create",
              method: "post",
              props: {
                order_id: orderId,
                refund_reason: e
              }
            };
            postOrder(searchParams.url, searchParams.props).then(resa => {
              if (resa) {
                window.location.reload();
                showToast("操作成功");
              }
            });
            this.orderRefundShow = false;
          }
        });
      });
    },
    orderDetailCallback(fn) {
      const orderId = this.orderId;
      if (this.orderInfo.mall.shop_info.skus.length === 1) {
        fn();
      } else {
        window.location.href = `${domain.wap}/product/evaluate/list?order_id=${orderId}`;
      }
    },
    callWeixinPay(json) {
      const loadStatusData = setInterval(() => {
        // eslint-disable-next-line no-undef
        if (WeixinJSBridge !== undefined) {
          clearInterval(loadStatusData);
          // eslint-disable-next-line no-undef
          WeixinJSBridge.invoke("getBrandWCPayRequest", json, res => {
            // eslint-disable-next-line no-undef
            WeixinJSBridge.log(res.err_msg);
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              this.PaySuccessBack();
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              showToast("取消支付");
            } else {
              showToast(res.err_msg);
            }
          });
        }
      }, 300);
    },
    PaySuccessBack() {
      window.location.href = `${domain.wap}/product/payment/success?pay_id=${this.pay_id}`;
    },
    // 客服按钮
    downApp() {
      const msg = "请移驾到「摩点App」中联系客服吧，小姐姐会快速回复你呦～";
      if (sessionStorage.getItem("miniProgram")) {
        showToast({
          msg: msg
        });
      } else {
        lowerdev.confirm({
          msg: msg,
          success: "打开App",
          cancel: "取消"
        }, () => {
          location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.modian.app";
        });
      }
    },
    reloadScroll() {
      const localTop = document.getElementsByClassName("order-list")[0].scrollTop || 0;
      const domHeight = document.getElementsByClassName("order-list")[0].scrollHeight || 0;
      const domClientHeight = document.getElementsByClassName("order-list")[0].clientHeight || 0;
      if (this.loading) {
        return false;
      }
      if (localTop && domHeight < localTop + domClientHeight + 50) {
        this.loading = true;
        this.page_index = this.page_index + 1;
        if (this.nav == 200) {
          this.$refs.afterSaleList.getList();
        } else {
          this.getList();
        }
      }
    }
  }
};